
import { defineComponent } from 'vue'
import {
    getPolicyCycleBillList
} from '@/api/invoice'
import { dateFormat } from '@/utils/util'
import global from '@/utils/global'

export default defineComponent({
    name: 'InvoiceBill',
    data () {
        return {
            searchValue: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tableData: [] as any
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        dateFormat,
        async handleCurrentChange () {
            const result = await getPolicyCycleBillList({
                search: this.searchValue,
                page: this.pageNum,
                pageSize: this.pageSize
            })
            this.total = result.total
            this.tableData = result.data
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },

        // 导出续保名单
        onExport (id: string) {
            window.location.href =
        process.env.VUE_APP_BASE_URL +
        '/policyCycleBill/export/?token=' + global.token
            this.$message.success('导出成功')
        }

    }
})
